import queryString from 'query-string'
import axios, {AxiosPromise} from 'axios'
import httpurl from '../common/httpurl';
import {localGet} from './localData';

let apiAxios = axios.create({
    // baseURL: "http://taxbox.sztaxton.com",
    timeout: 10000,
    headers: {'Content-type': 'application/x-www-form-urlencoded'},
});
/**
 * http request 请求拦截器
 */
apiAxios.interceptors.request.use(config => {
        let token = localGet("t");
        if (token) {  //拦截器，请求前添加token
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded',     // application/json or application/x-www-form-urlencoded
                'X-Token': `${token}`
            }
        } else {
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded',     // application/json or application/x-www-form-urlencoded
            }
        }
        return config
    }, err => {
        return Promise.reject(err)
    },
);
/**
 * http response 响应拦截器
 */
apiAxios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

const getArr = [
    httpurl.getToken,
    httpurl.homeQueryAd,
    httpurl.validatorImg,
    httpurl.courseQueryLabel,
    httpurl.newsGroupQuery,
    httpurl.newsQueryLabel,
    httpurl.queryByLabel,
    httpurl.queryNewsByGroup,
    httpurl.queryTopicNews,
    httpurl.newsQuery,
    httpurl.newsCheckNews,
    httpurl.newsQueryTopicGet,
    httpurl.getTrainAreaName,
    httpurl.queryServeCenter,
    httpurl.courseGoodsGet,
    httpurl.courseGoodsGetNew,
    httpurl.goodsqueryType,
    httpurl.getCourseList,
    httpurl.bookQueryRecommend,
    httpurl.bookQueryBook,
    httpurl.courseQueryNewest,
    httpurl.getAreaNameCount,
    httpurl.bookChapterList,
    httpurl.pptGetUpAndDown,
    httpurl.queryHot,
    httpurl.geTopicList,
    httpurl.userAffiliatedErp,
    httpurl.queryConfig,
    httpurl.appQueryList,
    httpurl.helpQuery,
    httpurl.getAreaNameCount,
    httpurl.courseQuery,
    httpurl.userGetInfo,
    httpurl.enterproseQuery,
    httpurl.wxLoginPc,
    httpurl.getChapter,
    httpurl.queryHonor,
    httpurl.getChapter,
    httpurl.queryTitle,
    httpurl.queryToppicLabel,
    httpurl.messageGet,
    httpurl.helpGet,
    httpurl.userLogout,
    httpurl.topicRecommend,
    httpurl.courseTrainRecommend,
    httpurl.courseGet,
    httpurl.courseLive,
];

/**
 * Post请求
 */
function post(url: string, payload = {}): AxiosPromise<any> {
    return apiAxios.post(url, queryString.stringify({
        ...payload,
    }))
}

/**
 * get 请求
 */
function get(url: string, payload = {}): AxiosPromise<any> {
    return apiAxios.get(url, {
        params: payload
    })
}

let request = function (url: string, payload: any) {
    if (!payload.os) {
        payload.os = 'pc_web'
    }
    if (getArr.indexOf(url) !== -1) {
        return get(url, payload)
    } else {
        return post(url, payload);
    }
};

export default request
