import React from 'react'; 
import { withRouter} from 'dva/router'
import { connect} from 'dva'
import "swiper/dist/css/swiper.min.css"
import pathToRegexp from 'path-to-regexp'
import "nprogress/nprogress.css"
import './App.scss';
import NProgress from 'nprogress'
import  HeaderNormal from './components/Header/HeaderNormal'
import FootNormal from "./components/Footer/FootNormal"

import "./assets/fonts/iconfont.css" 
import { urlconfig } from './common/urlconfig';

 
let currHref = '';
const App =({loading,children,app,dispatch}:any)=> {
  const {locationPathname,isInElectron}= app
  const href = window.location.href;
  if (currHref !== href) {  
      NProgress.start();
      if (!loading.global) {  
          NProgress.done(); 
          currHref = href;
          window.scrollTo(0, 0);    //页面滚回顶部
      }
  }

  if(pathToRegexp(`${urlconfig.courseplay.path}/:id`).test(locationPathname)){
    return  <React.Fragment>{children}</React.Fragment>
  }else{
  return ( <React.Fragment>
           <HeaderNormal app={app} dispatch={dispatch}/>
            {children}
            {!isInElectron ? <FootNormal/> : null }
       </React.Fragment> )
  } 
}
 
export default withRouter(connect(({  loading,app }:any) => ({  loading,app }))(App));
