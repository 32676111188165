/**
 * 扩展函数
 */

// 日期格式化
window.Date.prototype.format = function (format) {
  const o = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'H+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    S: this.getMilliseconds(),
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : (`00${o[k]}`).substr(`${o[k]}`.length))
    }
  }
  return format
}

/**
 * string 数字转换为金额，千分位分隔符
 */
window.String.prototype.formatToMoney=function(){
  // return this.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
  //     return $1 + ",";
  // });
  return parseFloat(this).toLocaleString();
}
window.Number.prototype.formatToMoney=function(){
  // return this.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
  //     return $1 + ",";
  // });
  return this.toLocaleString();
}