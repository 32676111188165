import * as React from "react";
import "./logoadv.scss"
import Image from "../Image";
import {appModelType} from "../../../typescript/modelType";

const logo = require('../../../assets/logo.png');

export interface Props {
    app: appModelType;
    dispatch: any;
}

interface State {
}

export default class Index extends React.PureComponent<Props, State> {

    render() {
        const {topAdv} = this.props.app;
        return (
            <div className="container-wrap">
                <div className="container header-row">
                    <div className="header-sp-logo">
                        <a href="/" target="_blank" rel="noopener noreferrer" className="main-logo">
                            <Image src={logo} alt="logo"/>
                        </a>
                    </div>
                    <div className="header-sp-recs">
                        <div className="header-rec-wrap">
                            <a href={topAdv.url ? topAdv.url : '/'} target="_blank" rel="noopener noreferrer">
                                <Image src={topAdv.image ? topAdv.image : require('../../../assets/topadv.png')}
                                       alt="广告"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}