/**
 *  地址配置
 *
 */
const API='/api';     // 线上配置
// const API = process.env.NODE_ENV === "development" ? "" : "/api";
// const API ="http://taxbox.sztaxton.com";       //正式环境地址

export default {           //后端地址配置
  getToken:`${API}/account/user/getToken`,        //获取token
  homeQueryAd:`${API}/book/home/query/ad`,      //广告位
  login:`${API}/account/user/login`,     //个人登录
  wxLoginPc:`${API}/account/wx/wxLoginPC`,//微信登录
  userLogout:`${API}/account/user/logout`,   //退出登录
  validatorImg:`${API}/account/validator/img`,   //获取图形验证码
  userSendSms:`${API}/account/user/sendSms`,    //手机号注册 /发送短信
  userRegister:`${API}/account/user/register`,   //手机号注册
  userUpdatePwd:`${API}/account/user/updatePwd`,    //找回密码
  userAffiliatedErp:`${API}/account/user/affiliatedErp`,    //个人中心查询企业
  trainQueryUser:`${API}/course/train/query/user`, //用户已绑定企业
  queryVip:`${API}/account/enterprise/query/vip`,    //当前用户绑定的企业信息
  userUpdate:`${API}/account/user/update`,//修改个人信息
  userSendMail:`${API}/account/user/sendMail`,   //修改邮箱发送邮件
  userUpdateEmail:`${API}/account/user/updateEmail`,//确认修改邮件地址
  userSendMessage:`${API}/account/user/sendMessage`,//修改手机号码发送短信
  userUpdatePhone:`${API}/account/user/updatePhone`,    //确认修改手机号
  updatePwd:`${API}/account/user/update/pwd`, //修改密码
  updateContact:`${API}/account/enterprise/update/contact`,   //修改管理员联系信息
  enterpriseUserupdateRole:`${API}/account/erp/enterpriseUser/update/role`,    //管理员移交权限


  enterproseQuery:`${API}/account/enterprise/query`,//查询企业关联用户
  enterpriseErpQuery:`${API}/account/enterprise/erp/query`,    //查询企业详细信息

  uservailImgDo:`${API}/account/user/vailImg.do`,   //微信绑定手机号 发送短信
  userBindDo:`${API}/account/user/bind.do`,    //微信绑定手机号 确认绑定

  topicRecommend:`${API}/news/news/query/topic/recommend`,   //推荐专题

  courseQueryNewest:`${API}/course/course/query/newest`,    //最新面授
  queryLabel:`${API}/news/message/queryLabel`,   //消息查询
  messageGet:`${API}/news/message/get`,    //消息 查看内容
  newsGroupQuery:`${API}/news/news/newsGroup/query`, //资讯分组
  newsQueryAll:`${API}/news/news/query/all`,   //资讯查询
  newsQueryTopic:`${API}/news/news/query/topic`,   //专题查询
  courseQueryRecommend:`${API}/course/course/query/recommend`,   //推荐网课
  courseQueryLabel:`${API}/course/course/query/label`,   //网课标签
  newsQueryLabel:`${API}/news/news/query/label`,   //查询资讯标签
  queryByLabel:`${API}/news/news/queryByLabel`,// 根据标签查询资讯
  queryNewsByGroup:`${API}/news/news/queryNewsByGroup`,    //根据分组查询资讯
  newsQuery:`${API}/news/news/query`,//搜索资讯
  courseQuery:`${API}/course/course/query/name`,    //搜索网课
  queryToppicLabel:`${API}/course/course/query/topic/label`,   //根据标签查询专题、网课
  newsCheckNews:`${API}/news/news/checkNews`,   //查看资讯内容
  queryTopicNews:`${API}/news/news/query/topic/news`,    //专题页
  newsQueryTopicGet:`${API}/news/news/query/topic/get`,   //查看专题下资讯内容
  geTopicList:`${API}/news/news/get/topic/list`,    //专题查询内容时侧边推荐
  queryCategory:`${API}/course/courseCategory/query/category`,    //网课分类
  queryRecommend:`${API}/course/course/query/recommend`,   //  推荐网课
  courseQueryAll:`${API}/course/course/query/all`,   //网课列表
  courseChapterGet:`${API}/course/courseChapter/get`,   //获取网课视频地址
  courseQueryTopic:`${API}/course/course/query/topic`,//专题列表
  courseGetCourse:`${API}/course/course/get/course`,    //课程详情
  getCourseChapter:`${API}/course/courseChapter/get/courseChapter`,    //课程目录
  queryStudent:`${API}/course/course/query/student`, //同学

  getAreaNameCount:`${API}/course/train/get/areaNameCount`,    //区域培训次数
  getCourseList:`${API}/course/course/get/CourseList`, //面授列表
  getTrainMgr:`${API}/course/course/get/trainMgr`,      //面授详情
  courseGet:`${API}/course/course/get`,   //课程详情
  courseTrainRecommend:`${API}/course/course/train/recommend`,//面授推荐
  trainGetcourseId:`${API}/course/train/get/courseId`,      //开课计划
  getTrainAreaName:`${API}/course/train/get/TrainAreaName`, //报名地点
  queryAreaname:`${API}/course/train/query/areaName`,  //选择报名地点列表
  courseGetTrainMgr:`${API}/course/train/enroll`,   //参训人报名
  queryServeCenter:`${API}/course/goods/query/serveCenter`,    //服务中心列表
  queryConfig:`${API}/account/erp/serverConfig/query/config`,   //qq群 or 服务
  goodsqueryType:`${API}/course/goods/query/type`,//产品列表
  queryHot:`${API}/course/goods/query/hot`,    //热门产品
  courseGoodsGet:`${API}/course/goods/get`,    //商品描述
  courseGoodsGetNew:`${API}/course/goods/getNew`,    //商品描述
  bookQueryRecommend:`${API}/book/book/query/recommend`,   //推荐资料
  bookQueryBook:`${API}/book/book/query/book`,    // 根据分类查询资料
  bookChapterList:`${API}/book/book/query/chapter/list`,    //章节列表
  bookQueryContent:`${API}/book/book/query/chapter/content`,//查询内容

  pptGetUpAndDown:`${API}/book/ppt/get/upAndDown`,//资料 / 章节
  bookQueryType:`${API}/book/book/query/type`,    //查询期刊类型
  bookQueryChapter:`${API}/book/book/query/chapter`,   //查询期刊列表
  appQueryList:`${API}/account/app/query/list`,    //下载 /
  downloadFormQueryList:`${API}/pc_py/download/form_menu`,    //下载 /
  appQueryNode:`${API}/account/app/getNode`,    //查询子节点下的APP

  // courseChaptergetContent:`${API}/course/courseChapter/get/content `,   //查询视频播放

  userGetInfo:`${API}/account/user/getInfo`,    //查询个人信息
  queryCreditCode:`${API}/account/enterprise/query/creditCode`,   //根据信用码查询企业
  erpUerRel:`${API}/account/enterprise/userRel`,   //添加企业

  validatorSms:`${API}/account/validator/sms`,   //发送短信验证添加企业
  enterpriseSms:`${API}/account/enterprise/sms`,    //发送短信 验证短信i
  enterpriseReg:`${API}/account/enterprise/reg`,    //注册编码验证

  fileUpload:`${API}/account/file/upload`,   //上传图片
  enterpriseLicense:`${API}/account/enterprise/license`,    //确认提交 提交资料验证
  enterpriseUserUpdateRel:`${API}/account/erp/enterpriseUser/update/rel`,   //移除关联企业

  queryHonor:`${API}/news/message/query/honor`,    //公司荣誉

  helpQuery:`${API}/book/help/query`,   //帮助右侧导航
  getChapter:`${API}/book/help/get/chapter`,   //帮助 查询列表
  queryTitle:`${API}/book/help/query/title`,   //搜索帮助
  helpGet:`${API}/book/help/get`,   //帮助 查看内容

  feedbackInsert:`${API}/account/erp/feedback/insert`,   //投诉与建议

  getInvoice:`${API}/account/enterprise/get/invoice`,    //获取发票
  invoiceEmail:`${API}/account/enterprise/invoice/email`,//发送发票到邮件

  goodsQueryType:`${API}/account/goods/query/type`,//续费- 获取商品列表
  queryErpName:`${API}/account/enterprise/query/erpName`,//续费-查询企业名称
  wxpayPay:`${API}/pay/wxpay/pay`,   //请求微信支付二维码

  userSelectErp:`${API}/account/user/select/erp`,   //切换绑定企业
  userGetOrder:`${API}/account/user/get/order`,    //订单列表

  courseLive:`${API}/course/course/live`,    //直播地址

}
