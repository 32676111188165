
import * as React from "react";
import  Topbar from '../Elements/Topbar/index'
import  LogoAdv from '../Elements/LogoAdv/index'
import  TopNav from '../Elements/TopNav/index'
import { appModelType } from "../../typescript/modelType";

export interface Props {
  app:appModelType
  dispatch:any
}

interface State {
}
 class HeaderNormal extends React.PureComponent<Props,State>{
  render(){ 
    const {app,dispatch} = this.props;
    return (
      <header className="header-wrap">
        <Topbar app={app}/>
        {!app.isInElectron ? <LogoAdv dispatch={dispatch} app={app}/> : null }
        <TopNav dispatch={dispatch} app={app}/>
      </header>
    )
  }
}

export default HeaderNormal