import { ComponentType } from 'react';
import { DvaInstance } from 'dva';
import dynamic from 'dva/dynamic';

/**
 * 修复dynamic ts 错误
 */
interface dynamicOptions {
  app: DvaInstance;
  models?: () => PromiseLike<any>[];
  component: () => PromiseLike<any>;
}

export default (opts: dynamicOptions) => {
  const comp = dynamic(opts as any) as any;
  return comp as ComponentType<any>
};
