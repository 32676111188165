import * as React from "react";
import classNames from 'classnames'
import './topnav.scss'
import {urlconfig} from '../../../common/urlconfig'
import {Link} from 'dva/router'
import {appModelType} from "../../../typescript/modelType";

interface Props {
    app: appModelType;
    dispatch: any;
}

interface State {
}


class Index extends React.PureComponent<Props, State> {

    render() {
        const {dispatch} = this.props;
        const {locationPathname, loginStatus, isInElectron, currentCompanyInfo, userInfo} = this.props.app;
        const navArr = [
            urlconfig.index,
            urlconfig.information,
            urlconfig.onlinecourse,
            urlconfig.teach,
            urlconfig.service,
            urlconfig.product,
            urlconfig.f3tax,
            urlconfig.download,
            urlconfig.membershipInfo,
            urlconfig.help,
            urlconfig.about,
        ];
        const logout = () => {
            dispatch({
                type: "app/userLogout", payload: {}
            })
        }
        return (
            <div className="container-wrap topnav">
                <div className="topnav-header-gradient">
                    <div className="container header-row topnav-menu-container">
                        <div className="ui-flexbox ui-flexbox-justify-between">
                            <div>
                                <ul className="topnav-ul">
                                    {navArr.map((item, index) => {
                                        return <li key={index}
                                                   className={classNames({"sfHover": locationPathname.indexOf(item.path) !== -1})}>
                                            <Link to={item.path}>{item.name}</Link></li>
                                    })}
                                </ul>
                            </div>
                            <div>
                                {loginStatus ?
                                    <React.Fragment>
                                        <Link to={urlconfig.ucenter.path} className="topnav-link">个人中心<span
                                            className="topnav-link-span">({userInfo.name})</span></Link>
                                        {!isInElectron ? <span style={{cursor: 'pointer'}} onClick={() => logout()}
                                                               className="topnav-link">退出</span> : null}
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Link to={urlconfig.login.path} className="topnav-link">登录</Link>
                                        <span className="topnav-link-sp"></span>
                                        <Link to={urlconfig.userregister.path} className="topnav-link">注册</Link>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index
