import pathToRegexp from 'path-to-regexp'

//所有地址

export const urlconfig: any = {
    userregister: {
        path: '/login/userregister',
        name: "注册",
    },
    findpwd: {
        path: '/login/findpwd',
        name: "重置密码",
    },
    updatepwd: {
        path: '/updatepwd',
        name: "修改密码",
    },
    login: {
        path: '/login',
        name: "登录"
    },
    wxcb: {
        path: '/wxcb',
        name: "微信回调"
    },
    index: {
        path: "/index",
        name: "首页"
    },
    download: {
        path: "/download",
        name: "下载"
    },
    information: {
        path: "/information",
        name: "资讯"
    },
    informationlist: {
        path: "/information/list",
        name: "列表"
    },
    onlinecourse: {
        path: "/onlinecourse",
        name: "网课"
    },
    courseplay: {
        path: "/play",
        name: "在线学习"
    },
    teach: {
        path: "/teach",
        name: "面授"
    },
    service: {
        path: "/service",
        name: "服务"
    },
    product: {
        path: "/product",
        name: "产品"
    },
    f3tax: {
        path: "/f3",
        name: "金财慧税F3"
    },
    productvip: {
        path: "/product/vip",
        name: "中税会员"
    },
    membershipInfo: {
        path: "/membershipinfo",
        name: "会员资料"
    },
    answers: {
        path: "/answers",
        name: "问答"
    },
    about: {
        path: "/about",
        name: "关于"
    },
    messagelist: {
        path: "/messagelist",
        name: "消息列表"
    },
    taxinfo: {
        path: "/taxinfo",
        name: "税局动态"
    },
    contactus: {
        path: "/contactus",
        name: "联系我们"
    },
    legalstatement: {
        path: "/legalstatement",
        name: "法律声明"
    },
    wxbind: {
        path: "/wxbind",
        name: "微信绑定"
    },
    search: {
        path: "/information/search",
        name: "搜索"
    },
    infodetail: {
        path: "/information/detail",
        name: "正文"
    },
    infospecial: {
        path: "/information/special",
        name: "专题"
    },
    onlinecourseSearch: {
        path: "/course/search",
        name: "网课"
    },
    ucenter: {
        path: "/ucenter",
        name: "个人中心"
    },
    help: {
        path: "/help",
        name: "帮助"
    },
    helpsearch: {
        path: "/helpsearch",
        name: "帮助搜索"
    },
    helpdetail: {
        path: "/help/detail",
        name: "详情"
    },
    ucentercompany: {
        path: "/ucenter/company",
        name: "企业详情"
    },
    addcompany: {
        path: "/ucenter/companyadd",
        name: "添加企业"
    },
    renewals: {
        path: "/renewals",
        name: "续费"
    },
    ticket: {
        path: "/ticket",
        name: "发票获取"
    },
}

//需要权限验证的地址
export const needVerify: Array<string> = [
    // urlconfig.ucenter.path,
    urlconfig.answers.path,
    urlconfig.addcompany.path,
    urlconfig.ucenter.path,
    urlconfig.ticket.path,
    urlconfig.ucentercompany.path,
    `${urlconfig.renewals.path}/:id`,
    `${urlconfig.membershipInfo.path}/:id`,
    `${urlconfig.membershipInfo.path}/:id/:num`,
];

//检测该地址是否需要登录
export function checkAuthPath(path: string): boolean {
    let check = false;
    needVerify.forEach((value) => {
        if (pathToRegexp(value).test(path)) {
            check = true;
        }
    });
    return check
}

