import React from 'react'
import { DvaInstance } from 'dva';
import {Switch, Route, Redirect, routerRedux } from "dva/router";
import dynamic from './util/dynamic'
import {urlconfig} from './common/urlconfig'
import App from "./App"
import Pageloading from './components/Loading/Pageloading';



const { ConnectedRouter } = routerRedux


interface RoutersValue {
  app : DvaInstance,
  history: object,
}

const Routers = function ({history, app}: RoutersValue | any): JSX.Element {
    // const error = dynamic({
    //     app,
    //     component: () => import("./page/error/error")
    // });
    const baseRoutes = [
        {
            path: urlconfig.login.path,
            models: () => [import("./model/login")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Login/Login")
        },
        {
            path: urlconfig.index.path,
            models: () => [import("./model/index")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Index/index")
        },
        {
            path: urlconfig.download.path,
            models: () => [import("./model/download")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Download/index")
        },
        {
            path: urlconfig.userregister.path,
            models: () => [import("./model/register")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Register/register")
        },
        {
            path: urlconfig.findpwd.path,
            models: () => [import("./model/findpwd")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Findpwd/index")
        },
        {
            path: urlconfig.updatepwd.path,
            models: () => [],
            LoadingComponent: Pageloading,
            component: () => import("./page/Findpwd/update")
        },
        {
            path: urlconfig.ucentercompany.path,
            models: () => [import('./model/ucentercompany')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Company/index")
        },
        {
            path: urlconfig.addcompany.path,
            models: () => [import("./model/companyadd")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Addcompany/index")
        },
        {
            path: urlconfig.informationlist.path,
            models: () => [import("./model/informationbase"), import("./model/informationlist")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Informationlist/index")
        },
        {
            path: urlconfig.messagelist.path,
            models: () => [import('./model/messagebase'), import("./model/messagelist")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Messagelist/index")
        },
        {
            path: `${urlconfig.messagelist.path}/:id`,
            models: () => [import('./model/messagebase'), import("./model/messagedetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Messagelist/messagedetail")
        },
        {
            path: urlconfig.taxinfo.path,
            models: () => [import('./model/messagebase'), import("./model/taxinfo")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Taxinfo/index")
        },
        {
            path: `${urlconfig.taxinfo.path}/:id`,
            models: () => [import('./model/messagebase'), import("./model/taxinfodetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Taxinfo/taxinfoDetail")
        },
        {
            path: urlconfig.contactus.path,
            models: () => [],
            LoadingComponent: Pageloading,
            component: () => import("./page/Contactus/index")
        },
        {
            path: urlconfig.legalstatement.path,
            models: () => [],
            LoadingComponent: Pageloading,
            component: () => import("./page/Legalstatement/index")
        },
        {
            path: urlconfig.about.path,
            models: () => [import('./model/about')],
            LoadingComponent: Pageloading,
            component: () => import("./page/About/index")
        },
        {
            path: urlconfig.wxbind.path,
            models: () => [import('./model/login'), import("./model/wxbind")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Wxbind/index")
        },
        {
            path: urlconfig.search.path,
            models: () => [import("./model/informationbase"), import("./model/search"),],
            LoadingComponent: Pageloading,
            component: () => import("./page/Searchresult/information")
        },
        {
            path: urlconfig.infodetail.path,
            models: () => [import("./model/informationbase"), import("./model/informationdetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Infodetail/index")
        },
        {
            path: urlconfig.information.path,
            models: () => [import("./model/informationbase"), import("./model/information")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Information/index")
        },
        {
            path: urlconfig.infospecial.path,
            models: () => [import("./model/informationbase"), import("./model/infospecial")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Infospecial/index")
        },
        {
            path: `${urlconfig.infospecial.path}/:id`,
            models: () => [import("./model/informationbase"), import("./model/InfospecialItem")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Infospecial/Page")
        },
        {
            path: `${urlconfig.infospecial.path}/:id/:num`,
            models: () => [import("./model/insospidetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Infospecial/specialdetail")
        },
        {
            path: `${urlconfig.onlinecourse.path}/:id`,
            models: () => [import("./model/onlinecoursedetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Onlinecoursedetail/index")
        },
        {
            path: urlconfig.onlinecourse.path,
            models: () => [import("./model/onlinecoursebase"), import("./model/onlinecourse")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Onlinecourse/index")
        },
        {
            path: `${urlconfig.onlinecourseSearch.path}`,
            models: () => [import("./model/onlinecoursebase"), import("./model/onlinecourseSearch")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Searchresult/onlinecourse")
        },
        {
            path: urlconfig.teach.path,
            models: () => [import("./model/informationbase"), import("./model/Teach")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Teach/index")
        },
        {
            path: `${urlconfig.teach.path}/:id`,
            models: () => [import("./model/Teachdetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Teachdetail/index")
        },
        {
            path: urlconfig.service.path,
            models: () => [import("./model/informationbase"), import("./model/Service")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Service/index")
        },
        {
            path: urlconfig.product.path,
            models: () => [import("./model/product")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Product/index")
        },
        {
            path: `${urlconfig.product.path}/:id`,
            models: () => [import("./model/productdetail")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Product/softdetail")
        },
        {
            path: `${urlconfig.productvip.path}/:id`,
            models: () => [import("./model/productVipDetailModel")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Product/ProductVipDetail")
        },
        {
            path: urlconfig.f3tax.path,
            models: () => [import("./model/f3tax")],
            LoadingComponent: Pageloading,
            component: () => import("./page/f3tax/index")
        },
        {
            path: urlconfig.membershipInfo.path,
            models: () => [import('./model/memberbase'), import('./model/membershipInfo')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Membershipinfo/index")
        },
        {
            path: `${urlconfig.membershipInfo.path}/:id`,
            models: () => [import('./model/memberbase'), import('./model/memberlist')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Membershipinfo/list")
        },
        {
            path: `${urlconfig.membershipInfo.path}/:id/:num`,
            models: () => [import('./model/memberbase'), import('./model/memberdetail')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Membershipinfo/detail")
        },
        {
            path: urlconfig.ucenter.path,
            models: () => [import('./model/ucenter')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Ucenter/index")
        },
        {
            path: urlconfig.wxcb.path,
            models: () => [import('./model/login')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Login/wxcb")
        },
        {
            path: urlconfig.help.path,
            models: () => [import('./model/helpbase'), import('./model/help')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Help/index")
        },
        {
            path: urlconfig.helpsearch.path,
            models: () => [import('./model/helpbase'), import('./model/helpsearch')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Help/search")
        },
        {
            path: `${urlconfig.help.path}/:id`,
            // path:urlconfig.helpdetail.path,
            models: () => [import('./model/helpbase'), import('./model/helpdetail')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Help/detail")
        },
        {
            path: `${urlconfig.courseplay.path}/:id`,
            models: () => [import("./model/play")],
            LoadingComponent: Pageloading,
            component: () => import("./page/Video/index")
        },
        {
            path: `${urlconfig.renewals.path}/:id`,
            models: () => [import('./model/renewals')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Renewals/index")
        },
        {
            path: urlconfig.ticket.path,
            models: () => [import('./model/ticket')],
            LoadingComponent: Pageloading,
            component: () => import("./page/Ticket/index")
        },
    ];

    return (
        <ConnectedRouter history={history}>
            <App>
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to={urlconfig.index.path}/>)}/>
                    {
                        baseRoutes.map(({path, ...dynamics}, key) => (
                            <Route key={key}
                                   exact
                                   path={path}
                                   component={dynamic({
                                       app,
                                       ...dynamics,
                                   })}
                            />
                        ))
                    }
                    <Route render={() => (<Redirect to={urlconfig.index.path}/>)}/>
                    {/* 404 页面 */}
                    {/* <Route component={error} />  */}
                </Switch>
            </App>
        </ConnectedRouter>
    )
};

export default Routers

