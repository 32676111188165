import {AnyAction} from 'redux'
import Message from '../components/Message/Message';
import { localRemove } from './localData';
import {Md5} from 'ts-md5/dist/md5'
import queryString from 'query-string'
import config from '../common/config';
import { routerRedux } from 'dva/router';
import { urlconfig } from '../common/urlconfig';
//睡眠函数
export function sleep(ms:number):Promise<any>{
  return new Promise(resolve => setTimeout(resolve,ms));
}
//过滤html标签
export function filterHTMLTag(msg:string):string {
  if(!msg){ return ''}
  msg = msg.replace(/<\/?[^>]*>/g, ''); //去除HTML Tag
  msg = msg.replace(/[|]*\n/, '') //去除行尾空格
  msg = msg.replace(/&npsp;/ig, ''); //去掉npsp
  return msg;
}

//请求错误统一处理
export function* errorHandle(data:any,put: <A extends AnyAction>(action: A) => any,cbObj:any={}){
  if(data.c === -1){
    Message.error("登录授权已过期，请重新登录");
    localRemove('ls');
    localRemove('t');
    yield put({
      type:"app/updateState",
      payload:{
        loginStatus:false,
        token:""
      }
    });
    yield put({
      type:"app/loadToken",
      payload:{}
    });
    yield put(routerRedux.push({
      pathname:urlconfig.login.path,
      search:queryString.stringify({
        from:window.location.pathname
      })
    }));
    if(Object.keys(cbObj).length > 0){
      if(cbObj.isloadToken){
        //延时一秒获取重新获取
        yield sleep(1000)
        yield put({
          type:"getVerifyCode",
          payload:{}
        })
      }
    }
  }else if(data.c === -2){
      localRemove('ls');
      localRemove('t');
      yield put(routerRedux.push({
        pathname:urlconfig.login.path,
        search:queryString.stringify({
          from:window.location.pathname
        })
      }));
  }else {
    Message.error(data.m);
  }
}
/**
 * 对请求参数进行签名
 */
export function signForPayload(obj:object):object{
  let ps = createParams(obj);
  let sign= createSign(ps,config.encryptedCipher);
  return {
    ...obj,sign:sign,...ps
  }
}

function createParams(params:object){
  const md5 = new Md5();
  md5.appendStr((new Date().getTime()).toString());
  return {
      device_id:md5.end(),
      os:config.os,
      v:config.v,
      timestamp:new Date().getTime(),
      package_name:config.package_name,
      //uid:'',           //用户uid，没有值得时候设置为空
      //sign:'',      //所有参数签名
      channel:'default',
      ...params
  }
}
function createSign(params:any, sign_key = config.encryptedCipher) {
  let keys = []
  for (let k in params) {
      keys.push(k)
  }
  keys.sort()
  let str = ""
  for (let i in keys) {
      if (keys[i] === "sign") {
      continue
      }
      let paramVal = params[keys[i]];
      if (str.length === 0) {
      str = str + keys[i] + "=" + paramVal
      } else {
      str = str + "&" + keys[i] + "=" + paramVal
      }
  }

  if (str.length > 0) {
      str += "&"
  }
  str += "key=" + sign_key;
  const md5 = new Md5();
  md5.appendStr(str);
  let str1 = md5.end();
  // console.log(str+"的签名值为:"+str1)
  return str1
}
