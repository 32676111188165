import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import dva from 'dva'
// import {message} from 'antd'
import createLoading from 'dva-loading'
// import {createBrowserHistory} from 'history' 
import history from './util/history'
// import 'antd/dist/antd.css';
// import * as serviceWorker from './serviceWorker' 
import appModel from './model/app'
import appRouter from './router'

import "./common/extend"
import Message from './components/Message/Message';

const app = dva({
    ...createLoading({
      effects:true,
    }),
    history:history,
    onError(error){
      Message.error(error.message);
    }
});

// 2. Plugins
// app.use({});

// 3. Model
app.model(appModel);

// 4. Router
app.router(appRouter);

// 5. Start
app.start('#root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()