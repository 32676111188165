import React, { Component } from 'react';
import Lazyload from 'r-img-lazyload';

interface Props{
  src:string
  style?:any
  className?:string
  alt?:string
  onClick?:Function
}
interface State{

}
const defaultImage = require("../../../assets/default-image.png");
const notfoundImage = require("../../../assets/404.png");
//延迟加载图片组件
export default class extends Component<Props,State> { 
    render() { 
        const config = {
            options: {
                error: notfoundImage,
                loading: defaultImage
            },
            ...this.props
        };
        return <Lazyload {...config} />;
    }
}