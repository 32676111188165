import React, { Component } from 'react'; 

interface State {  
}
interface Props{ 
  show?:boolean;
  className?:string;
  children:React.ReactDOM|any;
}

export default class View extends Component<Props,State> {
  render() {
    const classNames = [];
    const { show = true, className = '', children } = this.props;
    const mixed:object|any = { style: { ...children.props.style } };
    if (!show) mixed.style.display = 'none';
    if (children.props.className) classNames.push(children.props.className);
    if (className) classNames.push(className);
    mixed.className = classNames.join(' ');

    return React.cloneElement(React.Children.only(children), mixed);
  }
}

 