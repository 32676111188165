import modelExtend from 'dva-model-extend'
import queryString from 'query-string'
import {routerRedux} from 'dva/router';
import {SubscriptionAPI, Model, EffectsCommandMap} from 'dva'
import {ReduxAction} from '../typescript/modelapi'
import common from './common';
import {localSave, localGet, localRemove, aesDecrypt} from '../util/localData';
import {urlconfig, checkAuthPath} from '../common/urlconfig'
import {appModelType} from '../typescript/modelType';
import request from '../util/request';
import httpurl from '../common/httpurl';
import {signForPayload, errorHandle} from '../util/func';
import Message from '../components/Message/Message'

const appModel: Model = modelExtend(common, {
    namespace: 'app',
    state: {
        locationPathname: '',
        locationQuery: {},
        token: "",   //token
        loginStatus: false,    //登录状态
        Notice: [],    //公告
        uCompany: [],    //用户已绑定的所有企业
        userInfo: {},      //用户信息
        currentCompanyInfo: {},    //当前绑定企业信息
        asideList: [],   //侧边广告
        topAdv: {},
        registerContent: "",   //用户注册协议
        isInElectron: false,   //默认非应用内打开
    },
    subscriptions: {
        setupHistory({dispatch, history}: SubscriptionAPI) {
            history.listen((location) => {
                dispatch({
                    type: 'updateState',
                    payload: {
                        locationPathname: location.pathname,
                        locationQuery: queryString.parse(location.search),
                    },
                });
                dispatch({type: "checkISElectron", payload: {}});
                dispatch({type: "checkToken", payload: {}});
                dispatch({type: "loadTopAdv", payload: {idx: 5}});
                dispatch({type: "autoLogin", payload: {}})
            })
        },
    },
    effects: {
        //检测是否在electron打开
        * checkISElectron(payload: ReduxAction, {select, put}: EffectsCommandMap) {
            let {locationQuery} = yield select((_: { app: appModelType; }) => _.app);

            let ui1 = localStorage.getItem("user1");
            let t1 = localStorage.getItem("t1");
            if (t1 != null) {
                localSave("t", t1);
                localStorage.removeItem("t1")
            }
            if (ui1 != null) {
                console.log(JSON.parse(ui1));
                localSave("ui", JSON.parse(ui1));
                localSave("ls", true);
                localStorage.removeItem("user1")
            }

            if (locationQuery.ls && locationQuery.ui && locationQuery.t) {
                let ui = JSON.parse(aesDecrypt(locationQuery.ui)),
                    t = JSON.parse(aesDecrypt(locationQuery.t)),
                    ls = JSON.parse(aesDecrypt(locationQuery.ls));
                localSave("t", t);
                localSave("ui", ui);
                localSave("ls", ls);
                yield put({
                    type: "updateState",
                    payload: {
                        isInElectron: true,
                        token: t,
                        userInfo: ui,
                        loginStatus: ls,
                    }
                })
            } else if (window.isInElectron) {
                yield put({
                    type: "updateState",
                    payload: {
                        isInElectron: true,
                    }
                })
            }
        },
        //检查权限

        * checkToken(payload: ReduxAction, {select, put}: EffectsCommandMap) {
            let {locationPathname, currentCompanyInfo = {}} = yield select((_: { app: appModelType; }) => _.app);
            let token = localGet("t") || "", loginStatus = localGet("ls") || false, userInfo = localGet("ui") || {};
            yield put({
                type: "updateState", payload: {loginStatus: loginStatus, token: token, userInfo: userInfo}
            });
            // 判断当前路径是否需要验证token 权限
            if (checkAuthPath(locationPathname) && (token === '' || !loginStatus)) {
                yield put(routerRedux.replace({
                    pathname: urlconfig.login.path,
                    search: queryString.stringify({
                        from: locationPathname
                    })
                }));
            }
            if (!token) {
                yield put({
                    type: "loadToken",
                    payload: {}
                });
            }
            //已登录并且没有用户绑定企业信息
            if (loginStatus && Object.keys(currentCompanyInfo).length === 0) {
                yield put({
                    type: "loadCurentCompany",
                    payload: {}
                });
            }
        },
        //加载token
        * loadToken({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const r = yield call(request, httpurl.getToken, payload);
            let {data} = r;
            if (data.c === 0) {
                yield put({
                    type: "updateToken",
                    payload: {
                        token: data.data.token
                    }
                });
            }
        },
        * loadNotice({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {Notice} = yield select((_: { app: appModelType; }) => _.app);
            if (Notice.length === 0) {
                const r = yield call(request, httpurl.queryLabel, payload);
                let {data} = r;
                if (data.c === 0) {
                    yield put({
                        type: "updateState",
                        payload: {
                            Notice: data.data.list
                        }
                    })
                }
            }
        },
        //加载用户企业
        * loadCompany({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {data} = yield call(request, httpurl.trainQueryUser, signForPayload(payload));
            if (data.c === 0) {
                yield put({
                    type: "updateState",
                    payload: {
                        uCompany: data.data
                    }
                })
            }
        },
        //加载当前绑定信息
        * loadCurentCompany({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {currentCompanyInfo, loginStatus} = yield select((_: { app: any; }) => _.app);
            if (loginStatus && Object.keys(currentCompanyInfo).length === 0) {
                const {data} = yield call(request, httpurl.queryVip, signForPayload(payload));
                if (data.c === 0) {
                    yield put({
                        type: "updateState",
                        payload: {
                            currentCompanyInfo: data.data ? data.data : {}
                        }
                    })
                } else if (data.c === -1) {
                    localRemove("ls");
                    localRemove("ui");
                    yield put({
                        type: "updateState",
                        payload: {
                            currentCompanyInfo: {},
                            loginStatus: false,
                            uCompany: [],    //用户已绑定的所有企业
                            userInfo: {},      //用户信息
                        }
                    })
                }
            }
        },
        //微信登录
        * wxcode({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {data} = yield call(request, httpurl.wxLoginPc, payload);
            if (data.c === '1') {
                yield put(routerRedux.push({
                    pathname: urlconfig.wxbind.path,
                    search: queryString.stringify({
                        openid: data.openid
                    })
                }))
            } else if (data.c === "0") {
                yield put({
                    type: "login/phoneLogin",
                    payload: {
                        phone: data.phone,
                        pwd: data.pwd,
                    }
                })
            } else {
                Message.error(data.m);
                yield put(routerRedux.push({
                    pathname: urlconfig.login.path
                }))
            }
        },
        //侧边广告
        * loadAside({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {asideList} = yield select((_: { app: appModelType; }) => _.app);
            if (asideList.length === 0) {
                const r = yield call(request, httpurl.homeQueryAd, payload);
                const {data} = r;
                if (data.c === 0) {
                    yield put({type: "updateState", payload: {asideList: data.data}});
                }
            }
        },
        // 顶部广告位
        * loadTopAdv({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {topAdv} = yield select((_: { app: appModelType; }) => _.app);
            if (!topAdv.id) {
                const r = yield call(request, httpurl.homeQueryAd, payload);
                const {data} = r;
                if (data.c === 0 && data.data && data.data.length > 0) {
                    yield put({type: "updateState", payload: {topAdv: data.data[0]}});
                }
            }
        },
        //投诉建议
        * feedbackInsert({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {data} = yield call(request, httpurl.feedbackInsert, payload);
            if (data.c === 0) {
                Message.success('提交成功');
            } else {
                yield call(errorHandle, data, put);
            }
        },
        //自动登录
        * autoLogin({payload}: ReduxAction, {put, call}: EffectsCommandMap) {
            let form = localGet("al") || null, loginState = localGet("ls") || false;
            if (form && !loginState) {
                form = JSON.parse(form);
                const r = yield call(request, httpurl.login, form);
                const {data} = r;
                if (data.c === 0) {
                    yield put({
                        type: "updateToken",
                        payload: {
                            token: data.data.token,
                            loginStatus: true,
                            userInfo: data.data
                        }
                    });
                    localSave("t", data.data.token);
                    localSave("ui", data.data);
                    localSave("ls", true);
                } else {
                    Message.error("自动登录失败，请手动登录");
                    localRemove("al")
                }
            }
        },
        //用户注册协议
        * queryConfig({payload}: ReduxAction, {put, call, select}: EffectsCommandMap) {
            const {registerContent} = yield select((_: { app: appModelType; }) => _.app);
            if (registerContent === '') {
                const {data} = yield call(request, httpurl.queryConfig, {key: "userRegister"});
                if (data.c === 0) {
                    yield put({
                        type: "updateState",
                        payload: {
                            registerContent: data.data.keyvalue
                        }
                    })
                }
            }
        },
        //退出登录
        * userLogout({payload}: ReduxAction, {select, put, call}: EffectsCommandMap) {
            const {data} = yield call(request, httpurl.userLogout, signForPayload(payload));
            if (data.c === 0) {
                Message.success('退出成功');
                localRemove("al");
                localRemove('ls');
                localRemove('ui');
                yield put(routerRedux.push({
                    pathname: urlconfig.login.path,
                }))
            } else {
                yield call(errorHandle, data, put);
            }
        },
    },
    reducers: {
        updateToken(state: any, {payload}: ReduxAction) {
            //存储token
            // console.log("token:",payload.token);
            localSave("t", payload.token);
            return {
                ...state,
                ...payload
            }
        },
    },
})

export default appModel
