import React from 'react';
// import { Component, PropTypes, Transition, View } from '../../libs';
// import {Animate}  from 'react-simple-animate';
import classNames from 'classnames'
import "./message.scss"
import View from '../../lib/View'
import icons from './assets';
import Image from '../Elements/Image';
// import { sleep } from '../../util/func';

 

interface State { 
  visible: boolean
}
interface Props{ 
  type: string;
  message: string|React.ReactDOM,
  duration: number, 
  customClass: string,
  iconClass: string,
  willUnmount:Function;
}
const _defaultProps = {
  type: 'info',
  duration: 3000, 
}

export default class Toast extends React.Component<Props,State> {
  static defaultProps = _defaultProps;
  private timeout:any=null;
  constructor(props:Props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    this.setState({
      visible: true
    })

    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  onClose() {
    this.stopTimer();

    this.setState({
      visible: false
    },()=> {
      this.props.willUnmount(); 
    });
  }

  startTimer() {
		if (this.props.duration > 0) {
			this.timeout = setTimeout(() => {
				this.onClose();
			}, this.props.duration)
		}
  }

  stopTimer() {
    clearTimeout(this.timeout);
  }

  render() {
    const { iconClass,type, customClass } = this.props;
    // const animateProps ={
    //   play:this.state.visible,
    //   duration:1000,
    //   delay:1000,
    //   onComplete: async () => {
    //     await sleep(5000)
    //     this.props.willUnmount(); 
    //   }
    // };
    return (       
        <View show={this.state.visible}>
          <div className={classNames('elme-message',"message-fade", customClass)} onMouseEnter={this.stopTimer.bind(this)} onMouseLeave={this.startTimer.bind(this)}>
            { !iconClass && <Image className="elme-message__img" alt="img" src={icons[type]} /> }
            <div className={classNames('elme-message__group', { 'is-with-icon': iconClass })}>
              { iconClass && <i className={classNames('el-message__icon', iconClass)}></i> }
              <p>{this.props.message}</p> 
            </div>
          </div>
        </View>
       
    )
  }
}

 

