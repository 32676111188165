import crypto from 'crypto-browserify'
import config from '../common/config'

/**
 *  加密根据key值来加密解密，  key值从config的系统配置引入
 */
const {encryptedCipher} = config
/**
 * 加密数据
 * @param {Object} 加密数据
 * @param {String} 加密密匙
 */
export function aesEncrypt(data:object|string, key=encryptedCipher):string {
    const cipher = crypto.createCipher('aes192', key);
    var crypted = cipher.update(data, 'utf8', 'hex');
    crypted += cipher.final('hex');
    return crypted;
}
/**
 * 解密数据
 * @param {String} 解密数据
 * @param {String} 解密密匙
 */
export function aesDecrypt(encrypted: string, key=encryptedCipher):string {
    const decipher = crypto.createDecipher('aes192', key);
    var decrypted = decipher.update(encrypted, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
}

/**
 * 获取本地存储的数据
 * @param {String} name
 * @returns {Object}
 */
export function localGet(name: string):any {
	let data = localStorage.getItem(name);
	data = data ? JSON.parse(aesDecrypt(data)) : null;
	// console.log(`读取存储数据：[${name}]=`, data)
	return data
}

/**
 * 本地存储数据
 * @param {String} name
 * @param {Object} value
 */
export function localSave(name: string,value:string|object|boolean):void{
	// console.log(`存储数据到本地：[${name}]=`,value)
    let data = aesEncrypt(JSON.stringify(value));
    window.localStorage.setItem(name,data);
}
/**
 * 删除本地存储
 * @param {String} name
 */
export function localRemove(name: string){
    window.localStorage.removeItem(name);
}
window.localGet=localGet;
window.localSave=localSave;