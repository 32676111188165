
import * as React from "react";
import {Link} from "dva/router"
import "./topbar.scss"
import { urlconfig } from "../../../common/urlconfig";
import { appModelType } from "../../../typescript/modelType";
export interface Props {
  app:appModelType;
}

interface State {
}

export default class Index extends React.PureComponent<Props,State>{
  render(){
    const {loginStatus,currentCompanyInfo} = this.props.app;
    const weekday=['星期日','星期一',"星期二","星期三","星期四","星期五","星期六"],now =new Date();
    const month=['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'];
    return (
    <div className="header-top-menu-full">
      <div className="container header-top-menu">
        <div className="header-row">
          <div className="header-sp-top-menu">
            {/* 时间 */}
            <div className="header-sp-top-menu-item">
              <div className="data_time">
                <span> {weekday[now.getDay()]}, {month[now.getMonth()]}  {now.getDate()}, {now.getFullYear()}</span>
              </div>
            </div>
            <div className="header-sp-top-menu-item">
              <div className="data_time">
                <span>会员服务热线： 40076-23366</span>
              </div>
            </div>
            <div className="header-sp-top-menu-item">
              <ul className="menu-top-menu">
                <li className="menu-item"><span>绑定权益：</span></li>
                {(loginStatus && Object.keys(currentCompanyInfo).length>0) ?
                <li className="menu-item"><Link to={`${urlconfig.ucentercompany.path}?erpId=${currentCompanyInfo.erp_id || ''}&creditCode=${currentCompanyInfo.creditCode || ''}`}>{currentCompanyInfo.name}</Link></li>
                  : <li className="menu-item"><Link to={urlconfig.addcompany.path}>未绑定企业（点击绑定）</Link></li>}

              </ul>
            </div>
          </div>

          <div className="header-sp-top-widget">
            {/* <span><a href="http://www.baidu.com" rel="noopener noreferrer" target="_blank" title="微博">
                <i className="iconfont icon-weibo"></i>
              </a></span>
            <span><a href="http://www.baidu.com" rel="noopener noreferrer" target="_blank" title="QQ">
              <i className="iconfont icon-qq"></i>
            </a></span>
            <span><a href="http://www.baidu.com" rel="noopener noreferrer" target="_blank" title="微信"><i className="iconfont icon-weixin1"></i></a></span>
            <span><a href="http://www.baidu.com" rel="noopener noreferrer" target="_blank" title="QQ空间"><i className="iconfont icon-QQkongjian"></i></a></span> */}
            <span className="span">
              <Link to={`${urlconfig.renewals.path}/1`}>续费</Link>
            </span>
            <span className="span">
              <Link to={urlconfig.ticket.path}>发票获取</Link>
            </span>
            <span className="span">
              <Link to={urlconfig.legalstatement.path}>法律声明</Link>
            </span>
            <span className="span">
              <Link to={urlconfig.contactus.path}>联系我们</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
