import * as React from "react";
import ReactDOM from "react-dom"
import Toast from './Toast'
 
const Message:any={};
function MessageFn(props:object|any= {}, type:string) {
  const div = document.createElement('div');
  const messageBox = document.getElementById('modal');
  if (messageBox) {
    messageBox.appendChild(div); 
  } else {
    const messageBox = document.createElement('div');
    messageBox.className = "el-message-content";
    messageBox.appendChild(div);
    document.body.appendChild(messageBox);
  }

  if (typeof props === 'string' || React.isValidElement(props)) {
    props = {
      message: props
    };
  }

  if (type) {
    props.type = type;
  }

  const component = React.createElement(Toast, Object.assign(props, {
    willUnmount: () => {
      if(messageBox){
        ReactDOM.unmountComponentAtNode(div);
        messageBox.removeChild(div);
      }
      if (props.onClose instanceof Function) {
        props.onClose();
      }
    }
  }));

  ReactDOM.render(component, div);
}
['success', 'warning', 'info', 'error'].forEach(type => {
  Message[type] = (options:object|any= {}) => {
    return MessageFn(options, type);
  };
});

export default Message