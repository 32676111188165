import React from 'react';
import classnames from 'classnames'
// import { Component, PropTypes } from '../../libs';


interface State { 
}
interface Props{ 
  loading?: Boolean,
  fullscreen?: Boolean,
  text?: string,
  className?:string;
  style?:Object;
  children?:any;
}
const _defaultProps = {
  loading: true
};
export default class Loading extends React.Component<Props,State> {
  static defaultProps = _defaultProps;
  componentWillUnmount(): void {
    this.enableScroll();
  }
  style(args:any) {
    const { style } = this.props;
    return Object.assign({}, args, style)
  }
  className(...args :any) {
    const { className } = this.props;
    return classnames.apply(this, args.concat([className]));
  }
  getStyle(): {
    position?: string,
    top?: number,
    right?: number,
    bottom?: number,
    left?: number,
    zIndex?: number
  } {
    if (this.props.fullscreen) {
      this.disableScroll();

      return {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 99999
      }
    } else {
      this.enableScroll();

      if (this.props.loading) {
        return {
          position: 'relative'
        }
      }
      return {};
    }
  }

  documentBody(): HTMLElement | null {
    return document.body;
  }

  disableScroll(): void {
    const documentBody = this.documentBody();
    if (documentBody) {
      documentBody.style.setProperty('overflow', 'hidden');
    }
  }

  enableScroll(): void {
    const documentBody = this.documentBody();
    if (documentBody) {
      documentBody.style.removeProperty('overflow');
    }
  }

  render() {
    const { loading, fullscreen, text } = this.props;

    return (
      <div style={this.style(this.getStyle())} className={this.className()}>
        { loading && (
          <div
            style={{
              display: 'block',
              position: 'absolute',
              zIndex: 657,
              backgroundColor: 'rgba(255, 255, 255, 0.901961)',
              margin: 0,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}>
            <div className={classnames('el-loading-spinner', {
              'is-full-screen': fullscreen
            })} style={{
              position: 'absolute',
              display: 'inline-block',
              left: 0
            }}>
              <svg className="circular" viewBox="25 25 50 50">
                <circle className="path" cx="50" cy="50" r="20" fill="none" />
              </svg>
              {
                text && <p className="el-loading-text">{text}</p>
              }
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}
 