import {ReduxAction} from '../typescript/modelapi'

const common = { 
  state:{
  }, 
  effects:{
      
  },
  reducers:{
    updateState(state:any,{payload}:ReduxAction){
      return state={
        ...state,
        ...payload
      }
    }, 
  },
}

export default common